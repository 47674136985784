export default function MircsLogoWhite (props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 257.33 163.33" {...props}>
      <title>MIRCS Logo</title>

      <ellipse fill="white" cx="129.31" cy="24.69" rx="24.33" ry="22.25"/>
      <rect fill="white" x="105.72" y="50.28" width="47.18" height="56.98" rx="3.81" ry="3.81"/>
      <ellipse fill="white" cx="189.15" cy="41.15" rx="19.7" ry="18.02"/>
      <rect fill="white" x="169.92" y="62.03" width="38.46" height="45.23" rx="3.81" ry="3.81"/>
      <ellipse fill="white" cx="239.25" cy="58.73" rx="14.39" ry="13.16"/>
      <rect fill="white" x="224.76" y="74.06" width="28.97" height="33.19" rx="3.81" ry="3.81"/>
      <ellipse fill="white" cx="67.5" cy="41.15" rx="19.7" ry="18.02"/>
      <rect fill="white" x="48.27" y="62.03" width="38.46" height="45.23" rx="3.81" ry="3.81" transform="translate(135 169.28) rotate(-180)"/>
      <ellipse fill="white" cx="17.4" cy="58.73" rx="14.39" ry="13.16"/>
      <rect fill="white" x="2.91" y="74.06" width="28.97" height="33.19" rx="3.81" ry="3.81" transform="translate(34.8 181.31) rotate(-180)"/>
      <path fill="white" d="M9.73,120.31H79.46a5.62,5.62,0,0,1,5.33,5.85v30.57a4.3,4.3,0,0,1-4.68,5.07H66.32s-5-.52-5-5V131.23H55.39V157a4.3,4.3,0,0,1-4.68,4.89H36.34s-3.54.5-3.54-3.89V131.1H26.51v26.54a5,5,0,0,1-5.27,4.23H8.43s-4.26.52-4.26-4.26V125.38S4.53,120.31,9.73,120.31Z"/>
      <rect fill="white" x="92.4" y="120.31" width="23.94" height="41.6" rx="3.37" ry="3.37"/>
      <path fill="white" d="M127.29,120.31h31s3.64,0,3.64,3.34v4.6s.17,2.17-2.95,2.17h-12.4V156.6s.43,5.3-4.6,5.3H127.29s-3.73.59-3.73-5.65v-32.7A3.43,3.43,0,0,1,127.29,120.31Z"/>
      <path fill="white" d="M172.34,120.31h29.73s2.8-.2,2.8,3.64v5.14s.17,1.43-3.12,1.43H190.17v21.72h12.55s1.92.39,1.92,1.92v5s.29,2.78-3.35,2.78H171.5a4.19,4.19,0,0,1-4.16-4.54V123.95S167.79,120.31,172.34,120.31Z"/>
      <path fill="white" d="M218.2,120.31h32.26s3.45.13,3.45,3.45v4.62s.13,2.08-3.64,2.08H236.06v7.42H248.9s5-.34,5,3.24v16.33s.19,4.47-4.16,4.47h-33.5a3.41,3.41,0,0,1-3.06-3.63v-3.84s-.52-3.19,5-3.19h13.92V145h-14s-4.89.07-4.89-4.49v-16S213.26,120.31,218.2,120.31Z"/>
    </svg>
  )
}
